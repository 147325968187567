const questions = [
    {
        question: `I'd like to know more about the things I should consider when I am planning my gradual return to work.`,
        content: {
            chapter: 5,
            anchor: 'Planning your return to work',
        },
    },
    {
        question: `I'd like some tips on how to address my work gap in my CV or when talking to a potential employer.`,
        content: {
            chapter: 5,
            anchor: 'The CV and cover letter, networking and interviews',
        },
    },
    {
        question: `I'd like to understand what my rights are regarding requesting reasonable accommodation from my employer.`,
        content: {
            chapter: 5,
            anchor: 'Information about your rights',
        },
    },
    {
        question: `I'd like to know if I have to tell a potential employer about my illness.`,
        content: {
            chapter: 5,
            anchor: 'The CV and cover letter, networking and interviews',
        },
    },
    {
        question: `I'd like some advice about how to talk to my employer and work colleagues about my cancer experience.`,
        content: {
            chapter: 5,
            anchor: 'Communication',
        },
    },
    {
        question: `I'll be looking for a new job and I'd like some tips and strategies that will help me.`,
        content: {
            chapter: 5,
            anchor: 'Looking for a new job',
        },
    },
    {
        question: `I'm thinking about changing my job or career and would like some advice on how I should start and what I should consider.`,
        content: {
            chapter: 5,
            anchor: 'Career change: Finding the right job',
        },
    },
    {
        question: `I've decided not to return to work/I can no longer work. What should I be considering?`,
        content: {
            chapter: 5,
            anchor: 'What if you can no longer work?',
        },
    },
    {
        question: `I think the people who cared for me during my treatment could use some support and advice now that I'm in recovery.`,
        content: {
            chapter: 6,
            anchor: 'Practical services and support for caregivers',
        },
    },
    {
        question: `I'd like some advice on dating and socializing now that I've completed treatment.`,
        content: {
            chapter: 3,
            anchor: 'With your friends',
        },
    },
    {
        question: `I feel like a different person now, but I'm not sure exactly how or why this is so.`,
        content: {
            chapter: 3,
            anchor: 'Thinking about relationships',
        },
    },
    {
        question: 'My cancer experience has changed my relationship with my partner, family, and/or friends.',
        content: {
            chapter: 3,
            anchor: 'Thinking about relationships',
        },
    },
];


const title = 'Your relationships, work and social life';
const description = 'Complete part three to receive a roadmap to recovery indicating the resources and guidance that may help you';

const step3 = {
    title,
    description,
    questions,
}
export default step3;
