import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import { Header, MOBILE_MODIFIER} from './header';
import Footer from './footer';
import Helmet from 'react-helmet';
import questionnaireSteps from '@config/questionnaire';
import StepsContext from '@context/steps';
import { useSavedData } from '@hooks/saved-data';
import Ad from '@molecules/ad';

import "./layout.scss"

const Layout = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [steps, setSteps] = useSavedData(questionnaireSteps);
  const pathname        = typeof window !== 'undefined' ? window?.location?.pathname.replace(/\//ig, ' ') : '';
  const currentLocation = pathname ? pathname : '';
  const classes         = classNames(className, currentLocation);

  useEffect(() => {
    const currentClass = document.documentElement.className;
    const is2XDevice = window.devicePixelRatio > 1 ? ' _2x' : '';
    document.documentElement.className += ` ${currentLocation}${is2XDevice}`;

    return () => document.documentElement.className = currentClass;
  });

  return (
    <StepsContext.Provider value={{steps, setSteps}}>
      <Ad />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} modifier={MOBILE_MODIFIER} />
      <section className={ `main__layout d-flex flex-column ${className}` }>
        <Helmet bodyAttributes={{ class: classes }} />
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>
            {children}
        </main>
        <Footer siteTitle={data.site.siteMetadata?.title || `Title`} />
      </section>
      </StepsContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  containerFluid: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  className: PropTypes.string,
}

Layout.defaultProps = {
  containerFluid: false,
  className: '',
};

export default Layout
