const questions = [{
        question: 'Fatigue is affecting my daily life.',
        content: {
            chapter: 2,
            anchor: 'Fatigue',
        },
    },
    {
        question: `I'm not sleeping well.`,
        content: {
            chapter: 2,
            anchor: 'Get a good night’s sleep',
        }
    },
    {
        question: `It's hard for me to concentrate on things for long periods of time.`,
        content: {
            chapter: 2,
            anchor: 'Memory and concentration issues',
        }
    },
    {
        question: `I'm having trouble remembering things.`,
        content: {
            chapter: 2,
            anchor: 'Memory and concentration issues',
        }
    },
    {
        question: `I'm experiencing pain since my treatment.`,
        content: {
            chapter: 2,
            anchor: 'Pain',
        }
    },
    {
        question: 'I have tingling and/or numbing sensations in my hands or feet.',
        content: {
            chapter: 2,
            anchor: 'Peripheral neuropathy',
        }
    },
    {
        question: 'My sense of taste has changed.',
        content: {
            chapter: 2,
            anchor: 'Mouth and teeth problems',
        }
    },
    {
        question: 'I have some discomfort in my mouth/teeth.',
        content: {
            chapter: 2,
            anchor: 'Mouth and teeth problems',
        }
    },
    {
        question: `I'm concerned about my weight gain/loss.`,
        content: {
            chapter: 2,
            anchor: 'Weight gain and loss',
        }
    },
    {
        question: `I'm experiencing loss of control of my bowel/bladder.`,
        content: {
            chapter: 2,
            anchor: 'Incontinence (loss of bladder and bowel control)',
        }
    },
    {
        question: `I've been experiencing problems with edema (swelling) since my cancer surgery.`,
        content: {
            chapter: 2,
            anchor: 'Lymphedema (swelling)',
        }
    },
    {
        question: 'I have questions about managing my ostomy.',
        content: {
            chapter: 2,
            anchor: 'Managing an ostomy after colorectal surgery',
        }
    },
    {
        question: 'I am taking hormone therapy – are there any symptoms I should be aware of?',
        content: {
            chapter: 2,
            anchor: 'Hormone therapy symptoms',
        }
    },
    {
        question: 'Why am I experiencing menopause symptoms?',
        content: {
            chapter: 2,
            anchor: 'Menopause symptoms (women)',
        }
    },
    {
        question: 'I was treated for prostate cancer —are there symptoms I should know about related to lower hormone levels?',
        content: {
            chapter: 2,
            anchor: 'Hormone deprivation symptoms (men)',
        }
    },
    {
        question: `I'm interested in learning about how I can manage my post-treatment side effects with healthy living.`,
        content: {
            chapter: 2,
            anchor: 'Managing side effects with healthy living',
        }
    },
    {
        question: `I'd like some tips on how to speak effectively with my healthcare team about my side effects.`,
        content: {
            chapter: 2,
            anchor: 'Communication',
        }
    },
    {
        question: `I'm interested in learning about complementary therapies (like meditation and acupuncture) and how they may help manage my side effects.`,
        content: {
            chapter: 2,
            anchor: 'Complementary therapies',
        }
    },
    {
        question: `I'd like to know more about how nutrition and an active lifestyle can help me recover and live well.`,
        content: {
            chapter: 4,
            anchor: 'Unpack this topic',
        }
    },
    {
        question: 'I wonder what types of foods I should eat to help manage my weight gain/loss.',
        content: {
            chapter: 4,
            anchor: 'Weight change',
        }
    },
    {
        question: `I'd like some nutrition tips for managing side effects.`,
        content: {
            chapter: 4,
            anchor: 'Managing side effects with food',
        }
    },
    {
        question: `I'd like to know more about how I can improve my physical energy.`,
        content: {
            chapter: 4,
            anchor: 'Physical activity',
        }
    },
    {
        question: 'I need to know what types of exercises, or physical training, are ok for me to do.',
        content: {
            chapter: 4,
            anchor: 'What is being active?',
        }
    },
    {
        question: 'I have some questions about sexuality and intimacy.',
        content: {
            chapter: 2,
            anchor: 'Changes in sexuality and intimacy',
        }
    },
    {
        question: `I'd like to know where I can find information about rehabilitation services.`,
        content: {
            chapter: 4,
            anchor: 'Section 5',
        }
    },
    {
        question: `I'd like to know how to search for reliable information about my illness and recovery.`,
        content: {
            chapter: 7,
            anchor: 'section 2',
        }
    },
];


const title = 'Your physical condition';
const description = 'We invite you to start the questionnaire with part one about your physical wellbeing. You will automaticallly continue with part two and three.';

const step1 = {
    title,
    description,
    questions,
}
export default step1;
