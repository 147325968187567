import React from "react"
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next"
import PropTypes from "prop-types"
import Logo from '@components/logo';

import './footer.scss';

const links = [
  {
    title: 'Start Questionnaire',
    to: '/identify-your-needs',
  },
  {
    title: 'Bibliography',
    to: '/bibliography',
  },
  {
    title: 'About Looking Forward',
    to: '/about-us',
  },
  {
    title: 'Submit Feedback',
    target: '_blank',
  },
];

export const feedbackLink = {
  en: 'https://docs.google.com/forms/d/e/1FAIpQLSc7yrhyZDkuDLxwos9VZEErE2aV4Hi8Kvv7CIR7KaypgKsbZg/viewform',
  fr: 'https://docs.google.com/forms/d/e/1FAIpQLScrRuuiorKfWxyX4ZPdkhK45UzHyYnx5RAhOggWiTNN3AjwKg/viewform',
}

const Footer = () => {
  const { t, i18n } = useTranslation();
  const feedbackUrl = feedbackLink[i18n.language];

  return (
      <footer className="container-fluid footer">
        <div className="row">
          <div className="col-12">
            <ul className="nav flex-column flex-xxl-row align-items-xxl-center">
              <li className="nav-item flex-xxl-fill">
                <Logo from={'footer'} />
              </li>
              {
                links.map(({ title, to = feedbackUrl, className = '', target }) => {
                  const classes = `nav-item flex-xxl-fill menu-item ${className ? className : ''}`;

                  const link = target ? (
                      <a href={to} target={target}>
                        <Trans>{title}</Trans>
                      </a>
                  ) : (
                      <Link to={to}>
                        <Trans>{title}</Trans>
                      </Link>
                  );

                  return (
                      <li className={classes} key={to}>
                        {link}
                      </li>
                  );
                })
              }
              <li className="nav-item flex-xxl-fill small">
                © {new Date().getFullYear()} | {t('All rights reserved')}
              </li>
            </ul>
          </div>
        </div>
      </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
