import React from 'react';
import { Link, useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

import LogoImg from '@images/logo.svg';

import './logo.scss';

const Logo = ({ from }) => {
    const { t } = useTranslation();
    const title = t('Looking Forward');
    const to = `/${from && `?ref=${from}`}`

    return (
        <Link
            to={to}
            title={title}
            aria-label={title}
            className={`lf-logo d-flex text-uppercase align-items-center from-${from}`}
        >
            <img src={LogoImg} alt={title} className="lf-logo-img" />
            <span className="d-inline-block ml-2 ml-md-3 lf-logo-text">
                <Trans>lfTitle</Trans>
            </span>
        </Link>
    )
}

Logo.propTypes = {
    from: PropTypes.string,
}

Logo.defaultProps = {
    from: ''
}

export default Logo
