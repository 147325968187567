import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import './ad.scss';
import {
    Button,
    BUTTON_TYPE_BUTTON,
} from '@atoms/button';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { feedbackLink } from '@components/footer';

const COMPONENT_CLASS = 'ad';

const Ad = () => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const linkUrl = feedbackLink[lang]
    const classes = classNames(
        COMPONENT_CLASS,
    );

    return (
        <article className={classes}>
            <div className="container">
                <div className="row">
                    <div className="col-12 py-4 font-weight-bold">
                        <Trans>Welcome to the launch of the Looking Forward website. We want to know what you think! What works, what doesn’t work, and how we can improve it.</Trans>
                        <div className="button-container mt-2">
                            <Button to={linkUrl} target="_blank" type={[BUTTON_TYPE_BUTTON]}>Submit feedback</Button>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};

Ad.proTypes= {
    className: PropTypes.string,
};

Ad.defaultProps = {
    showIcon: true,
};

export default Ad;
