import React, { useState, useEffect } from "react"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import PropTypes from "prop-types"

import {
  Button,
  BUTTON_TYPE_LINK,
  BUTTON_TYPE_BUTTON,
  BUTTON_TYPE_FILLED,
} from '@atoms/button';

import classNames from 'classnames';
import MenuButton from '@atoms/menu-button';
import { getIsThereRoadmap } from '@hooks/saved-data';
import StepsContext from '@context/steps';

import { languageLabel } from "@config";
import sanitizeUrl from '@utils/sanitize-url';
import Logo from './logo';

import './header.scss';

const HEADER_CLASS = 'header';
const MENU_OPENED_CLASS = 'menu--open';
const MY_ROADMAP_BUTTON_CLASS = 'menu--has-roadmap';
const MOBILE_MODIFIER = 'mobile';

const Header = ({ modifier }) => {
  const { t } = useTranslation();
  const { steps } = React.useContext(StepsContext);
  const [menuOpened, setMenuOpened] = useState(false);
  const { languages, originalPath, language } = useI18next();
  const [languageLink, setLanguageLink] = useState(originalPath);
  const filteredLanguages = languages.filter(lng => lng !== language);
  const isMobileModifier = modifier && modifier === MOBILE_MODIFIER;
  const isThereRoadmap = getIsThereRoadmap(steps);

  useEffect(() => {
    if (!menuOpened) {
      document.body.classList.remove(MENU_OPENED_CLASS);
    } else {
      document.body.classList.add(MENU_OPENED_CLASS);
    }
  }, [menuOpened]);

  useEffect(() => {
    if (!isThereRoadmap) {
      document.body.classList.remove(MY_ROADMAP_BUTTON_CLASS);
    } else {
      document.body.classList.add(MY_ROADMAP_BUTTON_CLASS);
    }

    return () => {
      document.body.classList.remove(MY_ROADMAP_BUTTON_CLASS);
    };
  }, [isThereRoadmap]);

  useEffect(() => {
    const pathParts = originalPath.split('/');
    const firstPart = pathParts[1];
    if (['chapitres', 'chapters'].includes(firstPart)) {
      let newPath = originalPath;
      if (firstPart === 'chapitres') {
        newPath = newPath.replace('chapitres', 'chapters').replace('chapitre', 'chapter');
      } else {
        newPath = newPath.replace('chapters', 'chapitres').replace('chapter', 'chapitre');
      }
      setLanguageLink(newPath);
    }
  }, [originalPath, languageLink, setLanguageLink]);

  const allChaptersLink = sanitizeUrl(t('All Chapters'));
  const onAllChaptersClick = () => {
    document.body.classList.remove(MENU_OPENED_CLASS);
  };

  useEffect(() => {
    if (!isMobileModifier) {
      const callback = () => {
        const isMenuOpenedClass = document.body.classList.contains(MENU_OPENED_CLASS);
        if (!isMenuOpenedClass && menuOpened) {
          setMenuOpened(false);
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(document.body, { attributes: true });

      return () => observer.disconnect();
    }
  }, [menuOpened, setMenuOpened, isMobileModifier]);

  const headerMenu = (
      <React.Fragment>
        <Button to={`${language === 'en' ? '/' : ''}#${allChaptersLink}`} onClick={onAllChaptersClick}>{t('All chapters')}</Button>
        { isThereRoadmap ? <Button to={`/questionnaire/#your-roadmap`} type={[BUTTON_TYPE_BUTTON, BUTTON_TYPE_FILLED]}>Your Roadmap</Button> : null }
        <Button to={`/identify-your-needs`}>Start Questionnaire</Button>
        {
          filteredLanguages.map(lng => {
            return (
              <Button to={languageLink} language={lng} key={lng} className="header__language" type={BUTTON_TYPE_LINK}>
                {languageLabel[lng]}
              </Button>
            );
          })
        }
        {!isMobileModifier ? (<MenuButton active={menuOpened} className="header__toggle" onClick={()=>setMenuOpened(!menuOpened)} />) : null}
      </React.Fragment>
    );


  const modifierClass = modifier ? `${HEADER_CLASS}--${modifier}` : null;
  const headerClasses = classNames(`${HEADER_CLASS} container-fluid`, modifierClass);

  if (isMobileModifier) {
    return (
      <header className={headerClasses}>
        <div className="row">
          <div className="col-12 header__options">
            { headerMenu }
          </div>
        </div>
      </header>
    )
  }

  return (
    <header className={headerClasses}>
      <div className="row align-items-center justify-content-between">
        <div className="col-auto header__logo">
          <Logo />
        </div>
        <div className="col header__options d-flex flex-row justify-content-end align-items-center pl-0 pl-md-2">
          { headerMenu }
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  modifier: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
  modifier: '',
}

export { Header, MOBILE_MODIFIER };
