
const questions = [
    {
        question: `I'd like to know more about the activities I can do that can help improve my emotional wellbeing.`,
        content: {
            chapter: 4,
            anchor: 'Emotional wellbeing',
        },
    },
    {
        question: 'I think fatigue is affecting my mood.',
        content: {
            chapter: 2,
            anchor: 'Fatigue',
        },
    },
    {
        question: 'I have concerns about the cancer coming back.',
        content: {
            chapter: 3,
            anchor: 'The fear of recurrence',
        },
    },
    {
        question: 'I feel down and unable to shake off my sadness. Am I depressed?',
        content: {
            chapter: 3,
            anchor: 'Feeling sad, lonely, depressed',
        },
    },
    {
        question: 'I often feel all alone in dealing with my cancer experience.',
        content: {
            chapter: 3,
            anchor: 'Feeling sad, lonely, depressed',
        },
    },
    {
        question: `I'm not as interested in sex as I used to be.`,
        content: {
            chapter: 2,
            anchor: 'Changes in sexuality and intimacy',
        },
    },
    {
        question: 'My emotions and moods are interfering with moving forward in my recovery.',
        content: {
            chapter: 3,
            anchor: 'Unpack this topic',
        },
    },
    {
        question: `I've had treatments that affect my body image.`,
        content: {
            chapter: 3,
            anchor: 'Body image concerns',
        },
    },
    {
        question: 'Anger is showing up in my life more than before.',
        content: {
            chapter: 3,
            anchor: 'Feeling anger',
        },
    },
    {
        question: `I'm feeling more anxious than usual.`,
        content: {
            chapter: 3,
            anchor: 'Feeling anxious',
        },
    },
    {
        question: `I think I need to talk to someone about what I am going through, but I'm not sure where to start.`,
        content: {
            chapter: 3,
            anchor: 'Healing over time and with support',
        },
    },
];


const title = 'Your emotional and spiritual wellbeing';
const description = 'You can assess your emotional and spiritual wellbeing in part two';

const step2 = {
    title,
    description,
    questions,
}
export default step2;
