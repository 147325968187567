import React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './button.scss';

const COMPONENT_CLASS = 'btn';
const BUTTON_TYPE_BUTTON = 'btn';
const BUTTON_TYPE_LINK = 'link';
const BUTTON_TYPE_GRADIENT = 'gradient';
const BUTTON_TYPE_GRADIENT_LINK = 'gradientLink';
const BUTTON_TYPE_RIGHT_ARROW = 'rightArrow';
const BUTTON_TYPE_UP_ARROW = 'upArrow';
const BUTTON_TYPE_FILLED = 'filled';
const BUTTON_TYPE_CIRCLE = 'circle';

const Button = ({ children, to, className, type, language, onClick, stretchedLink, ...props }) => {
  let types = [];
  if (!Array.isArray(type)) {
    types.push(type);
  } else {
    types = type;
  }

  const components = {
    span: <span />,
  };

  const hasArrow = types.includes(BUTTON_TYPE_RIGHT_ARROW) || types.includes(BUTTON_TYPE_UP_ARROW);
  const typeClass = types.reduce((acc, current) => {
    const currentClass = current === BUTTON_TYPE_BUTTON ? 'default' : current;

    return `${acc} ${COMPONENT_CLASS}--${currentClass}`;
  }, COMPONENT_CLASS);

  const classes = classNames(typeClass, className, {
    'stretched-link': stretchedLink,
  });

  const { target } = props || {};
  const isBlank = target === '_blank';
  const linkChildren = hasArrow
      ? (
          <React.Fragment>
          <span className="text">
              <Trans components={components}>{children}</Trans>
          </span>
            <span className="arrow"><span /></span>
          </React.Fragment>
      ) : <Trans components={components}>{children}</Trans>;

  if (isBlank) {
    return (
        <a href={to} {...props} className={classes} onClick={(e) => onClick(e)}>{linkChildren}</a>
    );
  }

  return (
      <Link to={to} {...props} className={classes} language={language} onClick={(e) => onClick(e)}>
        {linkChildren}
      </Link>
  );
};


Button.propTypes = {
  to: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([
      BUTTON_TYPE_BUTTON,
      BUTTON_TYPE_LINK,
      BUTTON_TYPE_GRADIENT,
      BUTTON_TYPE_GRADIENT_LINK,
      BUTTON_TYPE_RIGHT_ARROW,
      BUTTON_TYPE_FILLED,
      BUTTON_TYPE_UP_ARROW,
      BUTTON_TYPE_CIRCLE,
    ]),
  ]),
  className: PropTypes.string,
  language: PropTypes.string,
  onClick: PropTypes.func,
  stretchedLink: PropTypes.bool,
};

Button.defaultProps = {
  to: '#',
  className: '',
  type: BUTTON_TYPE_BUTTON,
  onClick: () => {
  },
  stretchedLink: false,
};

export {
  Button,
  BUTTON_TYPE_BUTTON,
  BUTTON_TYPE_LINK,
  BUTTON_TYPE_GRADIENT,
  BUTTON_TYPE_GRADIENT_LINK,
  BUTTON_TYPE_RIGHT_ARROW,
  BUTTON_TYPE_FILLED,
  BUTTON_TYPE_UP_ARROW,
  BUTTON_TYPE_CIRCLE,
};
