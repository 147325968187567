import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import './menu-button.scss';
const COMPONENT_CLASS = 'menuButton';

const MenuButton = ({ active, onClick, className }) => {

    const { t } = useTranslation();

    const toggle = (e) => {
        e.preventDefault();
        if(onClick && typeof onClick === 'function') {
            onClick(!active);
        }
    };

    const activeClass = active ? `${COMPONENT_CLASS}--active` : null;
    const classes = classNames(COMPONENT_CLASS, className, activeClass);

    return (
        <Link to="#" onClick={(e) => toggle(e)} className={classes} aria-label={t('Toggle menu')}>
            <span>MENU</span>
        </Link>
    );
};


MenuButton.propTypes = {
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
};

MenuButton.defaultProps = {
    active: false,
    onClick: () => {},
    className: '',
};



export default MenuButton;