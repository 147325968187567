import step1 from './step-1';
import step2 from './step-2';
import step3 from './step-3';

const questionnaireSteps = [
    step1,
    step2,
    step3,
];

export default questionnaireSteps;
